.page__quem__spmos{
	padding-bottom: 0;

	h1{
		span {
			font-family: 'Montserrat';
		font-weight: 800;
		}
	}
}

.capa__quem__somos{
	background: #2f80c0;
	color: #FFF;
	margin-bottom: 130px;

	.container{
		padding-top: 100px;
		padding-bottom: 60px;
		position: relative;

		&::after{
			content:'';
			left: 50%;
			top: 100%;
			transform: translateX(-100%);
			border-left: 75px solid transparent;
			border-right: 75px solid #2f80c0;
			border-top: 50px solid #2f80c0;
			border-bottom: 50px solid transparent;
			position: absolute;
		}
	}
}

.section__depoimento{
	h2{
		font-family: 'Montserrat';
		font-weight: 800;
		color: #2f80c0;
		text-align: center;
		margin-bottom: 40px;

		@include media-breakpoint-up(md) {
			font-size: 40px;
		}
	}
}

.depoimento__item{
	width: 100%;
	max-width: 760px;
	position: relative;
	background-size: cover;
	background-position: center center;
	cursor: pointer;

	&::before{
		content:'';
		display: block;
		padding-bottom: percentage(490/760);
	}

	&::after{
		content:'';
		width: 100%;
		height: 100%;
		background: rgba(#000, .5);
		left: 0;
		top: 0;
		position: absolute;
		opacity: 0;
		transition: opacity .3s linear;
	}

	&:hover {
		&::after{
			opacity: 1;
		}

		.fas{
			background: lighten(#2f80c0, 10%);
		}
	}

	.fas{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 50px;
		height: 50px;
		background: #2f80c0;
		color: #FFF;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		font-size: 20px;
		transition: all .3s linear;
		z-index: 2;
		box-shadow: 0 0 8ox rgba(#000, .5);
		pointer-events: none;
	}
}

.owl__depoimentos{
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: -33px;
	z-index: 2;

	.owl-stage-outer{
		padding-top: 33px;
		padding-bottom: 33px;
	}

	.owl-item{
		transition: all .3s linear;
	}

	.owl-item:hover{
		transform: scale(1.2);
		z-index: 3;
		box-shadow: 0 0 15px rgba(#000, .5);
	}

	.owl-next,
	.owl-prev{
		width: 40px;
		height: 40px;
		border-radius: 50%;
		@extend .btn, .btn-primary;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 10;
		box-shadow: 0 0 8px rgba(#000,.5);

		&.disabled{
			box-shadow: none !important;
			background: #555;
			border-color: #555;
			cursor: default;
		}
	}

	.owl-prev{
		left: 15px;
	}

	.owl-next{
		right: 15px;
	}
}

.modal__depoimentos{
	.modal-dialog{
		max-width: calc(
			(16 * (100vh - 3.5rem)) / 9
		);
	}

	.modal-content,
	.modal-body{
		border-radius: 0;
	}

	.modal-body{
		@extend .embed-responsive;

		&::before{
			padding-bottom: percentage(9/16);
		}
	}
}
