.page__videos{
	background-color: #a4c14a;
	color: #FFF;

	@include media-breakpoint-up(lg) {
		background-image:
			url(../images/detalhe__internas.png),
			url(../images/detalhe__internas.png)
		;
		background-repeat: no-repeat;
		background-position:
			calc(50vw - 1306px) -69px,
			calc(50vw + 587px) calc(100% + 89px)
		;
	}

	.btn-carregar-mais{
		@include button-variant(#FFF, #FFF);
		color: #2f80c0;
		box-shadow: 0 0 8px rgba(#000, .4);
		border-radius: 0;
		transition: all .3s linear;
		padding: .5rem 1.5rem;

		&:hover{
			$scale: 15/14;
			color: #2f80c0;
			box-shadow: 0 0 15px rgba(#000, .4);
		}
	}

}

.video__item{
	width: 100%;
	max-width: 350px;
	background-color: #000;
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
	margin-bottom: 30px;
	cursor: pointer;
	position: relative;

	&::after{
		content: fa-content($fa-var-play);
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		@extend .btn, .btn-primary;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		box-shadow: 0 0 8px rgba(#000,.4);
		width: 50px;
		height: 50px;
		font-size: 20px;
	}

	&::before{
		content: '';
		display: block;
		padding-bottom: percentage(230/350);
	}
}


.section__videos__container{
	@include media-breakpoint-up(lg) {
		display: grid;
		grid-template-columns: repeat(
			3,
			calc(
				(100% - 60px) / 3
			)
		);
		grid-column-gap: 30px;
	}

	@include media-breakpoint-between(sm, md) {
		display: grid;
		grid-template-columns: repeat(
			2,
			calc(
				50% - 15px
			)
		);
		grid-column-gap: 30px;
	}
}
