.rodape{
	color: #FFF;
	background-color: #4bbaf3;

	.rodape__wrap{
		width: 100%;
		max-width: 1920px;
		margin-right: auto;
		margin-left: auto;

		@include media-breakpoint-up(xl) {
			background-image: var(--lazybg, none);
			background-position: center top;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}

	h2{
		margin-bottom: 30px;
		font-size: 30px;
		font-family: 'Montserrat';
		font-weight: 300;

		span{
			font-family: 'Montserrat';
			font-weight: 900;
		}

		@include media-breakpoint-up(md) {
			font-size: 45px;
		}
	}
}

.rodape__wrap{
	padding-top: 90px;
	padding-bottom: 60px;
}

.creditos{
	padding: 15px 0;
	background: #2f80c0;
	font-size: 12px;
	text-align: center;

	a{
		color: inherit;

		&:hover{
			color: #000;
		}
	}

	svg{
		fill: currentColor;
		width: toEm(50, 12);
		height: toEm(21, 12);
	}
}

.form__footer{
	--border-checkbox: #2f80c0;
	--bg-checkbox: #2f80c0;
	--check-checkbox: #FFF;

	.termos__contato{
		color: #000;

		a{
			color: inherit;
			text-decoration: underline;
		}
	}

	.form-group{
		position: relative;

		&.focus{
			--top: 0;
			--font-size: 12px;
		}
	}

	.label{
		position: absolute;
		left: calc(1px + .75rem);
		top: var(--top, 11px);
		font-size: var(--font-size, 1rem);
		transition: all .3s linear;
		z-index: 2;
	}

	.form-control{
		padding-top: 10px;
		padding-bottom: 10px;
		border-radius: 0;
		background: #0081c3;
		border-color: #0081c3;
		color: #FFF;
		height: 45px;
		position: relative;
		z-index: 0;

		&:focus {
			box-shadow: 0 0 .25rem 0.2rem rgb(255 255 255 / 25%);
		}

		&::placeholder{
			color: transparent;
		}
	}

	textarea.form-control{
		height: 160px;
	}

	.btn-enviar{
		background: #FFF;
		color: #4bbaf3;

		span{
			margin-left: 5px;
		}

		@include media-breakpoint-up(md) {
			width: 50px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			right: 10px;
			top: 100px;
			z-index: 2;
			position: absolute;
			font-size: 25px;

			span{
				@include sr-only;
			}
		}
	}
}

.contatos__footer{
	margin-bottom: 10px;

	.media-icon{
		width: 1.5em;
		height: 1.5em;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		border-radius: 50%;
		background: #FFF;
		color: #4bbaf3;
		margin-right: 10px;

		&.fa-phone{
			transform: scale(-1, 1);
		}

		&::before{
			font-size: .7em;
		}
	}

	.media-body{
		align-self: center;
	}

	a{
		display: inline-block;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		color: inherit;
		vertical-align: middle;
	}
}


.redes__sociais__footer{
	font-size: 20px;
	display: flex;
	margin-top: 20px;
	margin-bottom: 40px;

	a{
		display: flex;
		align-items: center;
		justify-content: center;
		width: toEm(35, 20);
		height: toEm(35, 20);
		background: #FFF;
		color: #4bbaf3;
		flex-shrink: 0;
		margin: 0 5px;
		border-radius: 50%;
		transition: all .3s linear;

		&:hover{
			text-decoration: none;
			background: #a4c14a;
			box-shadow: 0 0 0 5px rgba(#a4c14a, .5);
		}
	}
}
