@import "./topo__mobile";
@import "./topo__desktop";

.main__menu{
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;

	a{
		color: #FFF;
	}
}
