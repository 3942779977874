.md-select{
	position: relative;

	// &.focus{
	// 	.md-select-label{
	// 		--t: 0;
	// 		transform: translateY(-100%);
	// 		font-size: 12px;
	// 	}
	// }
}

.md-select-output{
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&::before{
		content: '';
		display: block;
		margin-top: 0.5em;
		border-left: 0.5em solid transparent;
		border-right: 0.5em solid transparent;
		border-top: 0.5em solid currentColor;
		float: right;
		margin-left: 0.75rem;
	}
}

.md-select-options{
	padding-top: 0;
	padding-bottom: 0;
	width: 100%;
	max-height: 250px;
	overflow: auto;

	li {
		padding: 10px 15px;
		cursor: pointer;

		&:first-child{
			border-top-left-radius: 0.25rem;
			border-top-right-radius: 0.25rem;
		}

		&:last-child{
			border-bottom-left-radius: 0.25rem;
			border-bottom-right-radius: 0.25rem;
		}

		&.disabled{
			color: #6c757d;
			pointer-events: none;
			background: #FFF;
		}

		&.selected{
			background: $primary;
			color: color-contrast($primary);
		}

		&:not(.selected):hover{
			background: #eee;
		}
	}
}

.md-select-label{
	// position: absolute;
	// left: var(--l, 0.75rem);
	// top: var(--t, 0.375rem);
	// transition: all 0.3s linear;
	// z-index: 2;
}
