.page__lagos{
	padding-bottom: 0;
	overflow: hidden;

	.bar{
		height: 12px;
		background: linear-gradient(
			to right,
			var(--bar1, #4bbaf3) 0%,
			var(--bar1, #4bbaf3) 50%,
			var(--bar2, #3080c0) 50%,
			var(--bar2, #3080c0) 100%
		);
		background-size: 50vw 12px;
	}

	.section__mais__que__um__lago{
		background: #3485c5;

		.row{
			align-items: center
		}

		.btn{
			@include button-variant(#4bbaf3, #4bbaf3);
			font-weight: 800;
			color: #FFF;
		}
	}
}

.capa__lagos{
	background-color: #b0bf9f;
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	max-height: 830px;
	padding-top: 80px;
	padding-bottom: 80px;
	background-repeat: no-repeat;

	@include media-breakpoint-up(xl) {
		height: 100vh;
		background-position: center 77px;
		background-attachment: fixed;

		.container{
			max-height: calc(830px - 160px);
		}
	}

	@include media-breakpoint-down(sm) {
		background-size: cover;
	}
}

.capa__body{
	@include media-breakpoint-up(lg) {
		--padding: 40px;
	}

	@include media-breakpoint-down(md) {
		margin-right: auto;
		margin-left: auto;
	}

	background: rgba(#000,.8);
	padding: var(--padding, 20px);
	color: #FFF;
	width: 100%;
	max-width: 480px;

	h1{
		text-align: center;
		margin-bottom: 20px;
		font-family: 'Darloune';
		line-height: 1;

		@include media-breakpoint-up(lg) {
			font-size: 60px;
		}
	}

	.content__editable{
		max-height: 300px;
		overflow: auto;
		margin-right: calc(var(--padding, 20px) * -1);
		padding-right: var(--padding, 20px);
		text-align: justify;
	}
}

.section__possibilidades{
	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	background-color: #8fab38;
	color: #FFF;
	padding-top: 50px;
	text-align: center;
	margin-bottom: 60px;

	h2{
		font-family: 'Montserrat';
		font-weight: 800;
		color: #FFF;
		margin-bottom: 30px;

		@include media-breakpoint-up(md) {
			font-size: 40px;
		}
	}

	.section__wrapper{
		@include media-breakpoint-only(lg) {
			display: grid;
			grid-template-columns: repeat(3, 33.33%);
			grid-template-rows: repeat(2, auto);

			.text__area{
				grid-column: 2/3;
				grid-row: 1/4;
				padding-bottom: 50px;
			}

			.image{
				max-width: 200px;
				margin: auto;
			}

			.image-1,
			.image-2{
				grid-column: 1/2;
			}

			.image-3,
			.image-4{
				grid-column: 3/4;
			}

			.image-1,
			.image-3{
				grid-row: 1/2;
			}

			.image-2,
			.image-4{
				grid-row: 2/3;
			}
		}

		@include media-breakpoint-up(xl) {
			width: 1920px;
			position: relative;
			left: 50%;
			transform: translateX(-50%);
			display: grid;
			grid-template-columns: 80px 230px 184px 150px 93px 420px 123px 281px 1fr;
			grid-template-rows: 1fr 120px 286px;

			.text__area{
				grid-column: 5/span 3;
				grid-row: 1/span 2;
				position: relative;
				z-index: 5;
			}

			.image-1{
				grid-column: 2/4;
				grid-row: 1/3;
				margin-top: auto;
			}

			.image-2{
				grid-column: 3/6;
				grid-row: 2/4;
				margin-top: auto;
				transform: translateY(30px);
			}

			.image-3{
				grid-column: 8/10;
				grid-row: 1/2;
				margin-top: auto;
				margin-left: auto;
				margin-right: auto;
			}

			.image-4{
				grid-column: 7/10;
				grid-row: 2/4;
				margin-top: 30px;
			}
		}
	}

	.text__area{
		padding-right: 15px;
		padding-left: 15px;
	}

	.image{
		background-position: center center;
		background-size: 100% 100%;
		width: 100%;
		background-image: var(--lazybg, none);

		&::before{
			content:'';
			display: block;
		}
	}

	.image-1{
		max-width: 383px;

		&.image-animated{
			animation: ornamental1 1s linear;
		}

		&::before{
			padding-bottom: percentage(419/383);
		}
	}

	.image-2{
		max-width: 377px;

		&.image-animated{
			animation: ornamental2 1s linear;
		}

		&::before{
			padding-bottom: percentage(395/377);
		}
	}

	.image-3{
		max-width: 409px;

		&.image-animated{
			animation: ornamental1 1s linear;
		}

		&::before{
			padding-bottom: percentage(350/409);
		}
	}

	.image-4{
		max-width: 357px;

		&::before{
			padding-bottom: percentage(370/357);
		}
	}
}

@keyframes ornamental1{
	from{
		transform: translate(150%, -100%);
		opacity: 0;
	}

	to{
		transform: translate(0,0);
		opacity: 1;
	}
}

@keyframes ornamental2{
	from{
		transform: translate(-100%, -100%);
	}

	to{
		transform: translate(0,30px);
	}
}

.page__lagos__processos{
	padding-bottom: 40px;

	@include media-breakpoint-up(lg) {
		display: grid;
		grid-template-columns: repeat(
			4,
			calc(
				(100% - 90px) / 4
			)
		);
		grid-gap: 30px;
	}

	@include media-breakpoint-only(sm) {
		max-width: 100%;
		padding-left: 0;
		padding-right: 0;
		overflow: auto;
		display: flex;
		scrollbar-width: none;

		&::-webkit-scrollbar{
			display: none;
		}

		&::before,
		&::after{
			content: '';
			display: block;
			width: 15px;
			height: 2px;
			flex-shrink: 0;
		}

		.card__processo{
			width: 55%;
			flex-shrink: 0;

			& + .card__processo{
				margin-left: 30px;
			}
		}
	}

	@include media-breakpoint-only(md) {
		max-width: 100%;
		padding-left: 0;
		padding-right: 0;
		overflow: auto;
		display: flex;
		scrollbar-width: none;

		&::-webkit-scrollbar{
			display: none;
		}

		&::before,
		&::after{
			content: '';
			display: block;
			width: 15px;
			height: 2px;
			flex-shrink: 0;
		}

		.card__processo{
			width: 40%;
			flex-shrink: 0;

			& + .card__processo{
				margin-left: 30px;
			}
		}
	}

	@include media-breakpoint-down(xs) {
		padding-left: 0;
		padding-right: 0;
		overflow: auto;
		display: flex;
		scrollbar-width: none;

		&::-webkit-scrollbar{
			display: none;
		}

		&::before,
		&::after{
			content: '';
			display: block;
			width: 15px;
			height: 2px;
			flex-shrink: 0;
		}

		.card__processo{
			width: 100%;
			max-width: calc(100% - 80px);
			flex-shrink: 0;

			& + .card__processo{
				margin-left: 15px;
			}
		}
	}
}
