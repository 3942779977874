.page__lagos{
	.bar{
		--bar1: #a4c14a;
		--bar2: #0081c3;
	}

	.section__obras__destaque,
	.section__manual{
		--bg-color: #0081c3;
	}

	.card__processo__title,
	.card__processo__img{
		color: #0081c3;
	}
}

.section__contemplacao{
	background-color: #4bbaf3;
	color: #FFF;
	padding-top: 56px;
	margin-bottom: 80px;

	background-image: var(--lazybg, none);
	background-repeat: repeat-x;
	animation: nuvens 18s linear infinite;

	.text__area{
		padding-right: 15px;
		padding-left: 15px;
		padding-bottom: 20px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}

	h2{
		font-family: 'Montserrat';
		font-weight: 800;
		color: #FFF;
		margin-bottom: 30px;

		@include media-breakpoint-up(md) {
			font-size: 40px;
		}
	}

	.section__wrapper{
		@include media-breakpoint-down(lg) {
			padding-bottom: 30px;
		}

		@include media-breakpoint-up(xl) {
			width: 1920px;
			position: relative;
			left: 50%;
			transform: translateX(-50%);
			display: grid;
			grid-template-columns: repeat(3, calc(100% / 3));
			grid-template-rows: auto 64px auto;

			.text__area{
				grid-column: 2/3;
				grid-row: 1/3;
			}

			.mergulhadora{
				grid-column: 1/2;
				grid-row: 1/4;
				margin-left: auto;
				align-self: flex-start;
			}

			.cadeira{
				grid-column: 1/2;
				grid-row: 2/4;
				margin-left: auto;
				position: relative;
				left: 220px;
				margin-bottom: -60px;
			}

			.mergulhador{
				grid-column: 3/4;
				grid-row: 1/4;
				margin-top: auto;
			}
		}
	}

	.mergulhadora,
	.mergulhador,
	.cadeira{
		width: 100%;

		@include media-breakpoint-down(lg) {
			display: none;
		}

		&::before{
			content: '';
			display: block;
		}
	}

	.mergulhadora{
		max-width: 474px;
		background: url(../images/mergulhadora.png);

		&::before{
			padding-bottom: percentage(612/474);
		}
	}

	.cadeira{
		max-width: 511px;
		background: url(../images/cadeira.png);

		&::before{
			padding-bottom: percentage(365/511);
		}
	}

	.mergulhador{
		max-width: 602px;
		background: url(../images/mergulhador.png);

		&::before{
			padding-bottom: percentage(486/602);
		}
	}
}


.page__lagos{
	width: 100%;

	.section__obras__destaque{
		background: var(--bg-color, #a4c14a);
		color: #FFF;
		padding-top: 60px;
		text-align: center;

		h2{
			font-family: 'Montserrat';
			font-weight: 800;
			margin-bottom: 20px;

			@include media-breakpoint-down(lg) {
				font-size: 40px;
			}

			@include media-breakpoint-up(xl) {
				font-size: 50px;
			}
		}
	}

	.lista__obras{
		margin-left: -8px;
		margin-right: -8px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		a{
			display: block;
			width: calc(50% - 18px);
			margin: 9px;
		}

		@include media-breakpoint-up(md) {
			a{
				max-width: calc(25% - 18px);
			}
		}

		@include media-breakpoint-only(sm) {
			grid-template-columns: repeat(
				3, calc(
					(100% - 36px) / 3
				)
			);
		}

		@include media-breakpoint-down(xs) {
			grid-template-columns: repeat(
				2, calc(
					(100% - 17px) / 2
				)
			);
		}
	}
}
