.topo__mobile{
	padding-bottom: 70px;

	img{
		@include img-fluid;
	}

	.main__menu{
		background: #000;
		position: relative;
		z-index: 4;

		a{
			display: block;
			padding: 10px 15px;
		}

		.active a,
		.active a:hover{
			background: #5eb1e3;
			color: #000;
		}
	}
}

.mobile__controls{
	position: fixed;
	width: 100%;
	height: 70px;
	display: grid;
	grid-template-columns: calc(100% - 60px) 60px;
	background: #000;
	color: #FFF;
	z-index: 999;

	.logo{
		display: inline-block;
		padding: 5px 10px;
		align-self: center;
		max-width: 100%;
	}
}

.btn-menu{
	@include button-variant(#5eb1e3, #5eb1e3);
	width: 48px;
	height: 48px;
	border-radius: 50%;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.topo__main{
	position: fixed;
	left: 0;
	top: 0;
	width: 250px;
	height: 100%;
	overflow: auto;
	z-index: 1050;
	background: #000;
	color: #FFF;

	&:not(.topo__main--shown) {
		transform: translateX(-100%);
	}

	&--shown{
		animation: slideInLeft .4s linear;
	}

	&--hide{
		animation: slideOutLeft .4s linear;
	}
}

.topo__brand{
	text-align: center;
	padding: 40px 15px;
	position: sticky;
	top: 0;
	z-index: 2;
}

.backdrop{
	width: 100%;
	height: 100%;
	background: rgba(#fff,.5);
	left: 0;
	top: 0;
	z-index: 1040;
	position: fixed;
	backdrop-filter: blur(4px);
	animation: fadeIn .4s linear;

	&--hide{
		animation: fadeOut .4s linear;
	}
}
