.page__blog{
	background: #2f80c0;
	color: #FFF;

	@include media-breakpoint-up(lg) {
		background-image:
			url(../images/detalhe__internas.png),
			url(../images/detalhe__internas.png)
		;
		background-repeat: no-repeat;
		background-position:
			calc(50vw - 1306px) -69px,
			calc(50vw + 587px) calc(100% + 89px)
		;
	}

	.btn-carregar-mais{
		@include button-variant(#FFF, #FFF);
		color: #2f80c0;
		box-shadow: 0 0 8px rgba(#000, .4);
		border-radius: 0;
		transition: all .3s linear;
		padding: .5rem 1.5rem;

		&:hover{
			$scale: 15/14;
			color: #2f80c0;
			box-shadow: 0 0 15px rgba(#000, .4);
		}
	}
}

.blog__posts__container{
	@include media-breakpoint-up(lg) {
		display: grid;
		grid-template-columns: repeat(
			3,
			calc(
				(100% - 60px) / 3
			)
		);
		grid-column-gap: 30px;
	}

	@include media-breakpoint-between(sm, md) {
		display: grid;
		grid-template-columns: repeat(
			2,
			calc(
				50% - 15px
			)
		);
		grid-column-gap: 30px;
	}
}

.blog__post{
	position: relative;
	width: 100%;
	max-width: 350px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;

	a{
		color: inherit;

		&:hover{
			text-decoration: none;

			.blog__post__titulo{
				text-decoration: underline;
			}
		}
	}
}

.blog__post__img{
	max-height: 230px;
	overflow: hidden;
	position: relative;
	margin-bottom: 10px;

	&::before{
		content:'';
		display: block;
		padding-bottom: percentage(230/350);
	}

	svg,
	img{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	img{
		background: #FFF;
	}

	svg{
		width: 100px;
		height: 100px;
	}

	&.no__image{
		background-color: #DDD;
	}
}

.blog__post__titulo{
	font-size: 16px;
	font-family: 'Montserrat';
		font-weight: 800;
	margin-bottom: 10px;
	@include line-clamp(2);
}

.blog__post__body{
	font-size: 14px;
	text-align: justify;
	@include line-clamp(3);
}
