// Margins
@include margin-variant(30);
@include margin-variant(40);
@include margin-variant(50);
@include margin-variant(60);
@include margin-variant(70);

[data-owl]{

	&:not(.ready){
		display: none;
	}

	&.disabled{
		cursor: not-allowed;
		opacity: 0.6;
	}
}


.slick-slider:not(.slick-initialized) {
	opacity: 0;
}

.relative{
	position: relative;
}

.content__editable{
	@include clearfix;

	img{
		max-width: 100%;
		height: auto !important;
	}
}

.before__animate{
	opacity: 0;
}

.modal-backdrop{
	opacity: 1 !important;
	background: rgba(#000,.5);
	backdrop-filter: blur(4px);
}
