.page__filtragem{
	padding-bottom: 0;

	.bar{
		height: 12px;
		background: linear-gradient(
			to right,
			#4bbaf3 0%,
			#4bbaf3 50%,
			#3080c0 50%,
			#3080c0 100%
		);
		background-size: 50vw 12px;
	}
}

.capa__filtragem{
	background-color: #b0bf9f;
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	max-height: 830px;
	padding-top: 80px;
	padding-bottom: 80px;
	background-repeat: no-repeat;

	@include media-breakpoint-up(xl) {
		height: 100vh;
		background-position: center 77px;
		background-attachment: fixed;

		.container{
			max-height: calc(830px - 160px);
		}
	}

	@include media-breakpoint-down(sm) {
		background-size: cover;
	}
}

.capa__body{
	@include media-breakpoint-up(lg) {
		--padding: 40px;
	}

	@include media-breakpoint-down(md) {
		margin-right: auto;
		margin-left: auto;
	}

	background: rgba(#000,.8);
	padding: var(--padding, 20px);
	color: #FFF;
	width: 100%;
	max-width: 480px;

	h1{
		text-align: center;
		margin-bottom: 20px;
		font-family: 'Darloune';

		@include media-breakpoint-up(lg) {
			font-size: 60px;
		}
	}

	.content__editable{
		max-height: 300px;
		overflow: auto;
		margin-right: calc(var(--padding, 20px) * -1);
		padding-right: var(--padding, 20px);
		text-align: justify;
	}
}

.section__filtragem__reciclagem{
	background-color: #a4c14a;
	color: #FFF;
	padding-top: 62px;
	padding-bottom: 40px;
	@include media-breakpoint-up(lg) {
		background-image:
			url(../images/detalhe__internas.png),
			url(../images/detalhe__internas.png)
		;
		background-repeat: no-repeat;
		background-position:
			calc(50vw - 1306px) -69px,
			calc(50vw + 587px) calc(100% + 89px)
		;
	}

	h2{
		font-family: 'Montserrat';
		font-weight: 800;
		margin-bottom: 30px;

		@include media-breakpoint-up(md) {
			font-size: 40px;
		}
	}
}

.section__etapas__filtragem{
	padding-top: 66px;
	padding-bottom: 66px;

	h2{
		color: #2f80c0;
		text-align: center;
		font-family: 'Montserrat';
		font-weight: 800;
		margin-bottom: 30px;

		@include media-breakpoint-up(md) {
			font-size: 40px;
		}
	}
}

ol.etapas{
	width: 100%;
	max-width: map-get($container-max-widths, xl);
	display: flex;
	overflow: auto;
	padding: 0;
	margin: 0 auto;
	list-style: none;

	&::before,
	&::after{
		content:'';
		width: 15px;
		height: 10px;
		flex-shrink: 0;
	}

	@include media-breakpoint-down(md) {
		scrollbar-width: none;

		&::-webkit-scrollbar{
			display: none;
		}
	}
}

.etapa__item{
	width: 100%;
	flex-shrink: 0;
	padding: 25px;
	border-radius: 7px;
	position: relative;
	background: var(--background, #222);
	color: var(--color, #fff);
	position: relative;
	border: 1px solid var(--background);
	display: flex;
	flex-direction: column;

	&:not(:last-child) {
		&::before{
			content:'';
			border-top: 20px solid transparent;
			border-bottom: 20px solid transparent;
			border-left: 20px solid var(--background);
			position: absolute;
			left: 100%;
			top: 50%;
			transform: translateY(-50%);
			z-index: 10;
		}
	}

	$bgs: (
		darkBlue  : #2f80c0,
		lightBlue : #4bbaf3,
		green     : #a4c14a,
		grey      : #bebebe
	);

	$orders: (
		darkBlue  : 1 5 9  13 17 21,
		lightBlue : 2 6 10 14 18 22,
		green     : 3 7 11 15 19 23,
		grey      : 4 8 12 16 20 24
	);

	@each $color, $list in $orders {
		$bg: map-get($bgs, $color);
		$els: '';

		@each $order in $list {
			@if $els != '' {
				$els: #{$els}, '&:nth-child(#{$order})';
			}
			@else {
				$els: '&:nth-child(#{$order})';
			}
		}

		#{$els} {
			--background: #{$bg};
		}
	}

	@include media-breakpoint-up(lg) {
		max-width: calc((100% - 30px) / 4);
	}

	@include media-breakpoint-only(md) {
		max-width: 40%;
	}

	@include media-breakpoint-only(sm) {
		max-width: 270px;
	}

	@include media-breakpoint-down(xs) {
		max-width: calc(100% - 60px);
	}

}

.etapa__img{
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 110px;
	height: 110px;
	overflow: hidden;
	margin-bottom: 10px;

	&.no__image{
		background: rgba(#fff, .4);
		border-radius: 4px;

		svg{
			width: 40%;
			height: 40%;
		}
	}
}

.etapa__titulo{
	font-family: 'Montserrat';
		font-weight: 800;
	text-align: center;
	margin-bottom: 10px;

	@include media-breakpoint-up(lg) {
		font-size: 25px;
	}
}

.etapa__body{
	margin-top: auto;
}

.section__vantagens__tecnologia{
	background: #1a1d20;
	color: #FFF;
	padding-top: 70px;

	.container{
		&::after{
			content:'';
			background: url(../images/img-730x281.png);
			display: block;
			width: 100%;
			padding-bottom: percentage(281/730);
			background-size: 100% 100%;
		}
	}

	h2{
		color: var(--marker, currentColor);
		margin-bottom: 30px;
		font-family: 'Montserrat';
		font-weight: 800;

		@include media-breakpoint-up(md) {
			font-size: 40px;
		}
	}

	ul{
		list-style: none;
		padding-left: 0;

		li {
			padding-left: 40px;
			position: relative;
			min-height: 30px;
			margin-bottom: 10px;

			&::before{
				content:'';
				width: 30px;
				height: 30px;
				background: var(--marker, currentColor);
				mask-image: url(../images/list-style.png);
				mask-size: 100% 100%;
				position: absolute;
				left: 0;
			}
		}
	}

	.section__vantagens{
		--marker: #4bbaf3;
	}

	.section__tecnologia{
		--marker: #a4c14a;
	}

	@include media-breakpoint-down(md) {
		.section__vantagens,
		.section__tecnologia{
			margin-bottom: 40px;
		}
	}

	@include media-breakpoint-up(lg) {
		.container{
			display: grid;
			grid-template-columns: repeat(2, calc(50% - 15px));
			grid-template-rows: auto auto 1fr;
			grid-template-areas:
				'V T'
				'V I'
				'N I'
			;
			grid-column-gap: 30px;

			&::after{
				grid-area: I;
				transform: translateY(10px);
			}
		}


		.section__vantagens{
			grid-area: V;
		}

		.section__tecnologia{
			grid-area: T;
		}
	}
}

.section__manual{
	background-color: var(--bg-color, #a4c14a);
	color: #FFF;
	padding-top: 62px;

	@include media-breakpoint-up(lg) {
		background-image:
			url(../images/detalhe__internas.png),
			url(../images/detalhe__internas.png)
		;
		background-repeat: no-repeat;
		background-position:
			calc(50vw - 1306px) -69px,
			calc(50vw + 587px) calc(100% + 89px)
		;
	}

	@include media-breakpoint-down(md) {
		text-align: center;

		.col-lg-4 + .col-lg-4{
			margin-top: 40px;
		}
	}

	h2{
		font-family: 'Montserrat';
		font-weight: 800;
		font-size: 1em;
		text-shadow: 0 0 4px #000;

		span{
			font-family: 'Montserrat';
			font-weight: 500;
			color: #000;
			font-size: .75em;
			display: block;
			line-height: 1.5;
			text-shadow: none;
		}

	}

	.hgroup{
		text-align: left;
		display: inline-block;

		@include media-breakpoint-up(md) {
			font-size: 40px;
			line-height: 1;
			width: 100%;
		}


		p{
			text-align: right;
			font-size: .75em;
			text-shadow: 0 0 4px #000;
		}

		@include media-breakpoint-up(xl) {
			font-size: 50px;

			p{
				font-size: 38px;
			}
		}
	}

	@include media-breakpoint-up(xl) {

		.col-lg-4.align-self-center{
			z-index: 2;
		}

		img{
			position: relative;
			// left: 50%;
			transform: translateX(-20px);
			max-width: 490px;
			pointer-events: none;
		}
	}

	.form-group{
		position: relative;

		&.focus{
			--top: 0;
			--font-size: 12px;
		}
	}

	.label{
		position: absolute;
		left: calc(1px + .75rem);
		top: var(--top, 11px);
		font-size: var(--font-size, 1rem);
		transition: all .3s linear;
		z-index: 2;
		color: #000;
	}

	.form-control{
		padding-top: 10px;
		padding-bottom: 10px;
		border-radius: 0;
		background: #FFF;
		border-color: #FFF;
		color: #000;
		height: 45px;
		position: relative;
		z-index: 0;

		&:focus {
			box-shadow: 0 0 .25rem 0.2rem rgb(255 255 255 / 25%);
		}

		&::placeholder{
			color: transparent;
		}
	}

	textarea.form-control{
		height: 160px;
	}

	.termos__contato{
		a{
			color: inherit;
			text-decoration: underline;
		}
	}

	.termos__contato__label::before{
		border-radius: 50%;
		width: 20px;
		height: 20px;
		background: transparent;
		border: 2px solid #FFF;
		top: 0;
	}

	.termos__contato__input:checked~.termos__contato__label::before{
		content: fa-content($fa-var-circle);
		color: #FFF;
		font-size: 10px;
	}

	.btn-enviar{
		@include button-variant(#4bbaf3, #4bbaf3);
		color: #FFF !important;
		padding: 1rem 2rem;
		min-width: 180px;
		margin-top: 15px;
		font-family: 'Montserrat';
		font-weight: 800;
		text-shadow: 0 0 2px rgba(#000,.4);

		&:hover{
			box-shadow: 0 0 8px rgba(#000,.4);
		}
	}

	$states: (
		success : $success,
		error   : $danger,
		warning : $warning
	);

	.message__validation{
		display: inline-block;
		padding: 10px;
		border-radius: 4px;
		margin-top: 10px;
		position: relative;
		border: 1px solid transparent;
		min-width: 180px;
		text-align: center;
		animation: rubberBand 1s linear;

		&::before{
			content:'';
			position: absolute;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid transparent;
			left: 50%;
			bottom: 100%;
			transform: translateX(-50%);
		}
	}

	@each $state, $color in $states {
		.has__#{$state} {
			$contrast: color-contrast($color);

			.message__validation{
				background: $color;
				color: $contrast;
				filter: drop-shadow(0 0 2px rgba(#000,.8));

				&::before{
					border-bottom-color: $color;
				}
			}
		}
	}
}
