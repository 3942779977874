@import "_fontfaces";

body{
	font-size: 14px;
	font-family: 'Montserrat';
	font-weight: 400;
}

.content__editable{
	$h: 'h1,.h1';

	@for $i from 2 through 6 {
		$h: '#{$h}, h#{$i}, .h#{$i}';
	}

	#{$h}{
		font-family: 'Montserrat';
		font-weight: 500;
	}
}
