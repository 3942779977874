// bg-position(x,y) width height
$icons: (
	'bitcoin': 0px 0px 128px 128px,
	'gold': -138px 0px 128px 128px,
);

@if $nativeCssIconVarialables {
	:root{
		@each $icon,$value in $icons {
			/*icon-#{$icon}*/
			--icon-#{$icon}-width: #{nth($value, 3)};
			--icon-#{$icon}-height: #{nth($value, 4)};
			--icon-#{$icon}-bg: #{nth($value, 1)} #{nth($value, 2)};
		}
	}
}

$spriteWidth: round(266px / 3);
$spriteHeight: round(128px / 3);

.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	background-size: #{$spriteWidth} #{$spriteHeight};

	display: inline-block;
	vertical-align: middle;

}

.icon__mask{
	mask-image: url('../images/sprites.png');
	mask-repeat:no-repeat;
	mask-size: #{$spriteWidth} #{$spriteHeight};

	display: inline-block;
	vertical-align: middle;
	background-color: currentColor;

}

@each $icon,$value in $icons {
	.#{$icon} {
		width: round(nth($value,3) / 3);
		height: round(nth($value,4) / 3);
		$sprite-position: #{round(nth($value,1) / 3)} #{round(nth($value,2) / 3)};

		&.icon{
			background-position: $sprite-position;
		}

		&.icon__mask{
			mask-position: $sprite-position;
		}
	}
}
