.lagos__organicos{
	overflow: hidden;

	.bar{
		--bar1: #a4c14a;
		--bar2: #4bbaf3;
	}

	.section__manual,
	.section__obras__destaque{
		--bg-color: #a4c14a !important;
	}

	.card__processo__title,
	.card__processo__img{
		color: #a4c14a !important;
	}

	@include media-breakpoint-down(md) {
		.image-1,
		.image-2,
		.image-3 {
			display: none;
		}

		.image-4{
			margin: 40px auto 0;
		}
	}

	.section__mais__que__um__lago{
		background: #98C23C;
		color: #000;
		text-shadow: none;

		.row{
			align-items: center;
		}

		h1{
			color: inherit;
		}

		.btn{
			@include button-variant(#2981C3, #2981C3);
			font-weight: 800;
			color: #FFF;
		}
	}
}

.section__mais__que__um__lago{
	color: #FFF;
	padding-top: 105px;
	background-image: var(--lazybg, none);
	background-position: center top;
	background-repeat: repeat-x;
	text-shadow: 0 0 1px #000;

	.btn{
		padding: 1rem 2rem;
		min-width: 180px;
		border-radius: 3rem;
		transition: all .3s linear;

		&:hover{
			box-shadow: 0 0 8px rgba(#000,.5);
		}
	}

	&.pdb-100{
		padding-bottom: 100px;
	}


	h1{
		font-family: 'Montserrat';
		font-weight: 900;
		color: #FFF;
		margin-bottom: 30px;
		text-align: center;
		font-size: 38px;
	}

	&.mgb-0{
		margin-bottom: 0 !important;
	}

	@include media-breakpoint-down(lg) {
		padding-bottom: 60px;

		.text__area{
			width: 100%;
			max-width: map-get($container-max-widths, lg);
			margin-left: auto;
			margin-right: auto;
		}
	}

	.section__wrapper{

		background-image: var(--lazybg, none);
		background-repeat: repeat-x;
		animation: nuvens 18s linear infinite;

		@include media-breakpoint-up(xl) {
			width: 1920px;
			position: relative;
			left: 50%;
			transform: translateX(-50%);
			display: grid;
			grid-template-columns: 635px 155px 420px 115px 635px;
			grid-template-rows: 1fr auto;
			grid-template-areas:
				'N1 T T  C'
				'F  F N C C'
			;

			.text__area{
				grid-area: T;
				margin-bottom: -60px;
				position: relative;
				z-index: 2;
				grid-column: 2/5;
				grid-row: 1/2;
			}

			.img__familia,
			.img__criancas{
				grid-column: 1/3;
				grid-row: 2/3;
				align-self: flex-end;
				margin-left: auto;
			}

			.img__familia{
				margin-bottom: -16px;
			}

			.img__cachoeira,
			.img__cachoeira2{
				grid-column: 4/6;
				grid-row: 1/3;
				align-self: flex-end;
			}
		}
	}

	.text__area{
		padding-left: 15px;
		padding-right: 15px;
	}

	.img__familia{
		width: 100%;
		max-width: 707px;
		background-image: url(../images/img-familia.png);
		background-size: 100% 100%;

		&::before{
			content:'';
			display: block;
			padding-bottom: percentage(381/707);
		}

		@include media-breakpoint-down(lg) {
			display: none;
		}
	}

	.img__criancas{
		width: 100%;
		max-width: 707px;
		background-image: url(../images/img-criancas.png);
		background-size: 100% 100%;

		&::before{
			content:'';
			display: block;
			padding-bottom: percentage(335/782);
		}

		@include media-breakpoint-down(lg) {
			display: none;
		}
	}

	.img__cachoeira{
		width: 100%;
		max-width: 781px;
		background-image: url(../images/img-cachoeira.png);
		background-size: 100% 100%;
		margin-bottom: -35px;

		&::before{
			content:'';
			display: block;
			padding-bottom: percentage(560/781);
		}

		@include media-breakpoint-down(lg) {
			display: none;
		}
	}

	.img__cachoeira2{
		width: 100%;
		max-width: 688px;
		background-image: url(../images/img-cachoeira2.png);
		background-size: 100% 100%;
		margin-bottom: -35px;

		&::before{
			content:'';
			display: block;
			padding-bottom: percentage(567/688);
		}

		@include media-breakpoint-down(lg) {
			display: none;
		}
	}

	.btn-voltar{
		@include button-variant(#4bbaf3, #4bbaf3);
		padding: 1rem 2rem;
		border-radius: 2.5rem;
		color: #FFF;
		font-family: 'Montserrat';
		font-weight: 800;
		box-shadow: 0 0 8px rgba(#000,.4);
		text-shadow: -1px 1px rgba(#000,.4);
	}
}


@keyframes nuvens{
	from{
		background-position: 0 top;
	}

	to{
		background-position: -1366px top;
	}
}


.carousel__lagos{
	.carousel-controls{
		position: absolute;
		top: 50%;
		width: 100%;
		max-width: map-get($container-max-widths, xl);
		left: 50%;
		transform: translateX(-50%);
	}

	.carousel-control-prev,
	.carousel-control-next{
		width: 50px;
		height: 50px;
		border-radius: 50%;
		top: 0;
		@extend .btn;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #FFF;
		color: $primary;
		box-shadow: 0 0 10px rgba(#000,.5);

		&:hover{
			color: #FFF;
			background: $primary;
		}
	}

	.carousel-control-prev{
		left: 15px;
	}

	.carousel-control-next{
		right: 15px;
	}

	.carousel-control-prev-icon{
		background-color: currentColor;
		mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
		mask-position: center center;
		mask-size: 100% 100%;
		background-image: none;
	}

	.carousel-control-next-icon{
		background-color: currentColor;
		mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
		mask-position: center center;
		mask-size: 100% 100%;
		background-image: none;
	}
}
