.page__galeria{
	background-color: #a4c14a;
	color: #FFF;

	@include media-breakpoint-up(lg) {
		background-image:
			url(../images/detalhe__internas.png),
			url(../images/detalhe__internas.png)
		;
		background-repeat: no-repeat;
		background-position:
			calc(50vw - 1306px) -69px,
			calc(50vw + 587px) calc(100% + 89px)
		;
	}

	.btn-carregar-mais{
		@include button-variant(#FFF, #FFF);
		color: #2f80c0;
		box-shadow: 0 0 8px rgba(#000, .4);
		border-radius: 0;
		transition: all .3s linear;
		padding: .5rem 1.5rem;

		&:hover{
			$scale: 15/14;
			color: #2f80c0;
			box-shadow: 0 0 15px rgba(#000, .4);
		}
	}
}

.galeria__container{
	margin-bottom: 40px;

	@include media-breakpoint-up(md) {
		display: grid;
		grid-template-columns:
			repeat(3,
				calc(
					(100% - 60px) / 3
				)
			)
		;
		grid-gap: 30px;
	}

	@include media-breakpoint-down(sm) {
		display: grid;
		grid-template-columns: repeat(2, calc(50% - 15px));
		grid-gap: 30px;
	}
}

.modal__galeria{
	color: #333;
	font-family: 'Montserrat';

	.modal-title{
		font-weight: 900;
	}

	.modal--loading{
		.modal-title{
			// color: transparent;
			// background: #CCC;
		}

		.carousel-inner{
			background: #CCC;
			padding-bottom: percentage(1080/1920);
		}

		.thumbnails{
			background-image:
				radial-gradient(
					#CCC 20px,
					transparent 20px
				),
				radial-gradient(
					#CCC 20px,
					transparent 20px
				)
			;
			min-height: 40px;
			background-position:
				left center,
				right center
			;
			background-size:
				40px 40px,
				40px 40px
			;
			background-repeat: no-repeat;

			&::before{
				--size: calc(
					(100% - 60px) / 5
				);
				content:'';
				width: calc(100% - 90px);
				display: block;
				margin: 0 auto;
				background-color: #CCC;

				@include media-breakpoint-down(xs) {
					height: 61px;
				}

				@include media-breakpoint-only(sm) {
					height: 80px;
				}

				@include media-breakpoint-only(md) {
					height: 61px;
				}

				@include media-breakpoint-up(lg) {
					height: 120px;
				}
			}
		}
	}

	.carousel{
		margin-bottom: 15px;
	}

	.owl-carousel{
		padding-left: 45px;
		padding-right: 45px;
	}

	.owl-next,
	.owl-prev{
		width: 40px;
		height: 40px;
		@extend .btn, .btn-primary;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		transform: translateY(-20px);
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		&.disabled{
			background: #777;
			border-color: #777;
			pointer-events: none;
		}
	}

	.owl-prev{
		left: 0;
	}

	.owl-next{
		right: 0;
	}

	.thumbnails{
		a{
			display: block;
			width: 100%;
			max-width: 120px;
			overflow: hidden;
			border: 1px solid #FFF;
			transition: border .3s linear;
			position: relative;

			&.active{
				border-color: $primary;
			}

			&::before{
				content:'';
				padding-bottom: 100%;
				display: block;
			}

			img{
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
}
