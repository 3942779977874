.politica__de__privacidade{
	background: #2f80c0;
	color: #FFF;

	@include media-breakpoint-up(lg) {
		background-image:
			url(../images/detalhe__internas.png),
			url(../images/detalhe__internas.png)
		;
		background-repeat: no-repeat;
		background-position:
			calc(50vw - 1306px) -69px,
			calc(50vw + 587px) calc(100% + 89px)
		;
	}

	.btn-voltar{
		@include button-variant(#FFF, #FFF);
		color: #2f80c0;
		box-shadow: 0 0 8px rgba(#000, .4);
		border-radius: 0;
		transition: all .3s linear;

		&:hover{
			$scale: 15/14;
			color: #2f80c0;
			box-shadow: 0 0 15px rgba(#000, .4);
			transform: scale($scale);
		}
	}
}
