.page__contato{
	background-color: #a4c14a;
	color: #FFF;

	@include media-breakpoint-up(lg) {
		background-image:
			url(../images/bg-contato.png),
			url(../images/detalhe__internas.png)
		;
		background-repeat: no-repeat;
		background-position:
			center top,
			calc(50vw + 587px) calc(100% + 89px)
		;
	}

	.form-group{
		position: relative;

		&.focus{
			--top: 0;
			--font-size: 12px;
		}
	}

	.label{
		position: absolute;
		left: calc(1px + .75rem);
		top: var(--top, 11px);
		font-size: var(--font-size, 1rem);
		transition: all .3s linear;
		z-index: 2;
		color: #000;
	}

	.form-control{
		padding-top: 10px;
		padding-bottom: 10px;
		border-radius: 0;
		background: #FFF;
		border-color: #FFF;
		color: #000;
		height: 45px;
		position: relative;
		z-index: 0;

		&:focus {
			box-shadow: 0 0 .25rem 0.2rem rgb(255 255 255 / 25%);
		}

		&::placeholder{
			color: transparent;
		}
	}

	textarea.form-control{
		height: 160px;
	}

	.termos__contato{
		a{
			color: inherit;
			text-decoration: underline;
		}
	}

	.termos__contato__label::before{
		border-radius: 50%;
		width: 20px;
		height: 20px;
		background: transparent;
		border: 2px solid #FFF;
		top: 0;
	}

	.termos__contato__input:checked~.termos__contato__label::before{
		content: fa-content($fa-var-circle);
		color: #FFF;
		font-size: 10px;
	}

	.btn-enviar{
		@include button-variant(#4bbaf3, #4bbaf3);
		color: #FFF !important;
		padding: 1rem 2rem;
		min-width: 180px;
		margin-top: 15px;
		font-family: 'Montserrat';
		font-weight: 800;
		text-shadow: 0 0 2px rgba(#000,.4);

		&:hover{
			box-shadow: 0 0 8px rgba(#000,.4);
		}
	}

	$states: (
		success : $success,
		error   : $danger,
		warning : $warning
	);

	.message__validation{
		display: inline-block;
		padding: 10px;
		border-radius: 4px;
		margin-top: 10px;
		position: relative;
		border: 1px solid transparent;
		min-width: 180px;
		text-align: center;
		animation: rubberBand 1s linear;

		&::before{
			content:'';
			position: absolute;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid transparent;
			left: 50%;
			bottom: 100%;
			transform: translateX(-50%);
		}
	}

	@each $state, $color in $states {
		.has__#{$state} {
			$contrast: color-contrast($color);

			.message__validation{
				background: $color;
				color: $contrast;
				filter: drop-shadow(0 0 2px rgba(#000,.8));

				&::before{
					border-bottom-color: $color;
				}
			}
		}
	}

	.dados__contato{
		margin-bottom: 40px;
		display: flex;
		flex-wrap: wrap;

		a{
			color: inherit;
			display: inline;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
			vertical-align: baseline;
		}

		@include media-breakpoint-down(md) {
			margin-left: -15px;
			margin-right: -15px;

			.dados__contato__item{
				padding-left: 15px;
				padding-right: 15px;
				flex-grow: 1;
				margin-bottom: 30px;
			}

			.redes__sociais{
				width: 100%;
			}
		}

		@include media-breakpoint-up(lg) {

			.dados__contato__item{
				&.telefone{
					padding-right: 15px;
					border-right: 1px solid #FFF;
				}

				&.email{
					padding-left: 15px;
					padding-right: 15px;
					margin-left: auto;
					margin-right: auto;
					flex-grow: 1;
				}

				&.redes__sociais{
					padding-left: 15px;
					border-left: 1px solid #FFF;
				}
			}
		}
	}

	.dados__contato__item{
		.media-icon{
			margin-right: 10px;
			width: 16px;
			height: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			border-radius: 50%;
			background: #FFF;
			color: #a4c14a;
			font-size: 10px;

			&.fa-phone{
				transform: scale(-1, 1);
			}
		}
	}

	.dados__contato__title{
		font-size: 14px;
		font-family: 'Montserrat';font-weight: 700;
	}

	.redes__sociais{
		display: flex;
		justify-content: center;
		font-size: 20px;

		a{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 2em;
			height: 2em;
			border-radius: 50%;
			background: #FFF;
			color: #a4c14a;
			margin: 0 5px;
		}
	}
}
