.page__piscinasbiologicas{
	.bar{
		--bar1: #a4c14a;
		--bar2: #0081c3;
	}

	.section__obras__destaque,
	.section__manual{
		--bg-color: #01bbf4;
	}

	.card__processo__title,
	.card__processo__img{
		color: #01bbf4;
	}


	.section__manual{
		.btn-enviar{
			@include button-variant(#3080c0, #3080c0);
		}
	}


	.section__mais__que__um__lago{
		background: #43BBF4;
		color: #000;
		text-shadow: none;

		.row{
			align-items: center;
		}

		h1{
			color: inherit;
		}

		.btn{
			@include button-variant(#2981C3, #2981C3);
			font-weight: 800;
			color: #FFF;
		}
	}
}
