
.card__processo{
	text-align: center;
	font-size: 14px;
}

.card__processo__img{
	width: 140px;
	height: 140px;
	margin: 0 auto 10px;
	position: relative;
	overflow: hidden;

	&.no__image{
		background-color: #CCC;
	}

	.img{
		width: 100%;
		height: 100%;
		background-color: currentColor;
		mask-position: center center;
		mask-repeat: no-repeat;
		mask-size: contain;
	}

	.img,
	svg{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	svg{
		width: 35%;
		height: 35%;
	}
}

.card__processo__title{
	font-family: 'Montserrat';
	font-weight: 800;
	font-size: 24px;
	color: #a4c14a;
	margin-bottom: 10px;

	@include media-breakpoint-down(lg) {
		font-size: 20px;
	}
}
