#banner{
	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;

	.carousel-indicators {
		li {
			width: 18px;
			height: 18px;
			border-radius: 50%;
			opacity: 1;
			border: none;

			&.active{
				background: $primary;
				animation: rubberBand .4s linear;
			}
		}
	}
}

.selo__anos{
	position: absolute;
	right: percentage( 15 / 1920);
	bottom: percentage( 15/ 870);
	z-index: 998;
	font-family: 'Montserrat';font-weight: 700;
	width: percentage(290/1920);

	.anos{
		filter: drop-shadow(0 0 5px #000);
	}
}

.especialistas__index{
	padding-top: 100px;
	padding-bottom: 60px;
	background-color: #000;
	color: #FFF;

	h2{
		color: #FFF;
		font-family: 'Montserrat';
		font-weight: 800;
		line-height: 1;
		margin-bottom: 30px;

		@include media-breakpoint-down(sm) {
			font-size: 40px;
		}

		@include media-breakpoint-up(md) {
			font-size: 60px;
		}

		span{
			color: $primary;
			font-size: toEm(35, 70);
			display: block;
		}
	}

	.btn{
		padding: .75rem 2rem;
		border-radius: 0;
		min-width: 255px;
		font-family: 'Montserrat';
		font-weight: 700;
	}

	@include media-breakpoint-up(xl) {
		background-attachment: fixed;
	}

	@include media-breakpoint-up(md) {
		background-image: var(--lazybg, none);
		background-position: center center;
		background-size: cover;
	}
}

.section__servicos__index{
	padding-top: 115px;
	padding-bottom: 70px;
	background-color: #141414;
	color: #FFF;
	text-align: center;

	h2{
		font-family: 'Montserrat';
		font-weight: 800;
		margin-bottom: 40px;

		@include media-breakpoint-up(md) {
			font-size: 50px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 30px;
		}
	}

	@include media-breakpoint-up(md) {
		background-image: var(--lazybg, none);
		background-position: center center;
		background-attachment: fixed;
	}

	@include media-breakpoint-up(xl) {
		background-size: cover;
	}
}

.card__servico{
	text-align: center;
	margin-bottom: 30px;
	color: #00adef;
	transition: color .3s linear;

	&:nth-child(1) {
		color: #00adef;
	}

	&:nth-child(2) {
		color: #98c449;
	}

	&:nth-child(3) {
		color: #3585c4;
	}

	a{
		color: inherit;

		&:hover{
			text-decoration: none;
		}
	}
}

.card__servico__img{
	width: 140px;
	height: 140px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 15px;
	background-color: currentColor;
	mask-position: center center;
	mask-repeat: no-repeat;
}

.card__servico__title{
	font-family: 'Montserrat';
		font-weight: 800;

	@include media-breakpoint-up(md) {
		font-size: 30px;
		line-height: 1;
	}
}

.row__servicos{
	@include media-breakpoint-up(md) {
		display: grid;
		grid-template-columns: repeat(3,
			calc(
				(100% - 60px) / 3
			)
		);
		grid-column-gap: 30px;
	}

	@include media-breakpoint-only(sm) {
		display: grid;
		grid-template-columns: repeat(2,
			calc(
				50% - 15px
			)
		);
		grid-column-gap: 30px;
	}
}

.bg__verde{
	background-color: #a4c14a;
	color: #FFF;

	@include media-breakpoint-up(md) {
		background-image: var(--lazybg, none);
		background-position: center top;
		background-size: cover;
	}

	@include media-breakpoint-up(xl) {
		background-attachment: fixed;
	}
}

.feito__por__quem__entende{
	text-align: center;
	padding-top: 79px;
	padding-bottom: 48px;

	h2{
		font-family: 'Montserrat';
		font-weight: 800;
		text-shadow: 0 0 2px rgba(#000,.8);

		span{
			font-size: 2em;
		}

		@include media-breakpoint-up(md) {
			font-size: 25px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 15px;
		}
	}

	.content__editable{
		text-shadow: 0 0 2px rgba(#000,.8);
	}

	@include media-breakpoint-down(xs) {
		.col-sm-6{
			width: 50%;margin-bottom: 30px;
		}
	}
}

.lista__detaques{
	width: 100%;
	max-width: map-get($container-max-widths, xl);
	margin-left: auto;
	margin-right: auto;
	display: flex;
	justify-content: center;

	.lista__destaques__inner{
		max-width: 100%;
		display: flex;
		overflow: auto;
		scrollbar-width: none;

		&::before,
		&::after{
			content: '';
			display: block;
			width: 15px;
			height: 10px;
			flex-shrink: 0;
		}

		&::-webkit-scrollbar{
			display: none;
		}

		.card__destaque{
			width: 255px;
			flex-shrink: 0;
		}

		.card__destaque + .card__destaque{
			margin-left: 30px;
		}
	}
}

.card__destaque{
	position: relative;
	width: 100%;
	max-width: 255px;
	transition: transform .3s linear;

	&:hover{
		transform: translateY(-20px);
		--scale: 1.1;
	}

	a{
		color: #FFF;

		&:hover{
			color: #FFF;
			text-decoration: none;
			--opacity: 1;
			--y: 0;
		}
	}
}

.card__destaque__body{
	position: absolute;
	padding: 15px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	left: 0;
	top: 0;
	overflow: hidden;
	text-align: center;
	background: rgba(#4bbaf3, .5);
	color: #FFF;
	backdrop-filter: blur(4px);
	text-shadow: 0 0 2px #000;
	transition: opacity .3s linear;
	opacity: var(--opacity, 0);
}

.card__destaque__title{
	font-family: 'Montserrat';
		font-weight: 800;
	font-size: 20px;
	margin-bottom: 10px;
	transition: transform .3s linear, opacity .3s linear;
	transform: translateY(var(--y, -450px));
	opacity: var(--opacity, 0);
}

.card__destaque__label{
	transition: transform .3s linear, opacity .3s linear;
	transform: translateY(var(--y, 450px));
	opacity: var(--opacity, 0);
}

.card__image{
	position: relative;
	overflow: hidden;
	clip-path: inset(0 0 0 0);

	&::before{
		content:'';
		display: block;
		padding-bottom: percentage(450/255);
	}

	&.no__image{
		background: #CCC;
	}

	svg,
	img{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%) scale(var(--scale, 1));
		transition: transform .3s linear;
	}

	svg{
		width: 100px;
		height: 100px;
	}
}

.section__escolha{
	h2{
		margin-bottom: 30px;
		font-family: 'Montserrat';
		font-weight: 800;
		text-align: center;

		@include media-breakpoint-up(md) {
			font-size: 40px;
		}
	}
}

.slide__projetos{
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;

	.loader{
		width: 60px;
		height: 60px;
		border: 5px solid $primary;
		border-left-color: #FFF;
		border-radius: 50%;
		margin: 60px auto;
		animation: loadingLazyElements 1s linear infinite;
		filter: drop-shadow(0 0 5px rgba(#000,.5));
	}

	.slider{
		width: 100%;

		@include media-breakpoint-up(xl) {
			width: 1920px;
			left: 50%;
			transform: translateX(-50%);
			position: relative;
		}
	}

	.owl-stage-outer{
		padding-bottom: 30px;
		padding-top: 30px;
		margin-bottom: -30px;
	}

	.owl-item{
		transition: all .3s linear;

		&:hover{
			transform: scale(1.1);
			z-index: 3;
			box-shadow: 0 0 8px rgba(#000,.5);
		}
	}

	.modal__projeto__toggler{
		position: fixed;
		bottom: 100vh;
	}

	.owl-next,
	.owl-prev{
		@extend .btn;
		border-radius: 50%;
		width: 48px;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #FFF;
		box-shadow:0 0 8px rgba(#000,.8);
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		&.disalbed{
			opacity: .5;
			pointer-events: none;
		}

		&:hover{
			background: rgb(0, 129, 195);
			color: #FFF;
		}
	}

	.owl-prev{
		left: 15px;
	}

	.owl-next{
		right: 15px;
	}
}

.section__escolha{
	width: 100%;
	overflow: hidden;
}

.slide__projetos__nav{
	display: inline-flex;
	justify-content: center;
	list-style: none;
	padding-left: 0;
	margin-bottom: 30px;
	border: 1px solid #FFF;
	position: relative;
	max-width: 100%;
	flex-wrap: wrap;

	&::before,
	&::after{
		content:'';
		border-top: 4px dotted #FFF;
		width: 100px;
		top: 50%;
		transform: translateY(-50%);
		position: absolute;
	}

	&::before{
		right: 100%;
		margin-right: 10px;
	}

	&::after{
		left: 100%;
		margin-left: 10px;
	}

	li{
		padding: 2px;
	}

	.nav-slider{
		display: block;
		padding: .375rem .75rem;
		color: #FFF;
		transition: all .3s linear;
		font-family: 'Montserrat';
		font-weight: 800;
		min-width: 140px;
		text-align: center;
		font-size: 16px;
		text-decoration: none !important;

		&.active{
			background: $primary;
			color: #FFF;
		}

		&:not(.active):hover{
			background: #FFF;
			color: $primary;
			text-decoration: none;
		}
	}
}


.slide__projeto__item{
	cursor: pointer;
	img{
		pointer-events: none;
	}
}

.noticias__index{
	padding-top: 70px;
	padding-bottom: 30px;
	background: #000;
	color: #FFF;

	h2 span {
		font-family: 'Montserrat';
		font-weight: 800;
	}

	.btn{
		padding: 1rem 2rem;
	}
}

.mergulho{
	padding-top: 50px;
	padding-bottom: 50px;
	color: #FFF;
	background-color: #4bbaf3;
	overflow: hidden;
	position: relative;
	perspective: 3px;

	h2{
		font-family: 'Montserrat';
		font-weight: 800;
	}

	.btn{
		font-family: 'Montserrat';font-weight: 700;
		width: 100%;
		max-width: 255px;
		padding: 1rem 2rem;
		color: #4bbaf3;
		transition: all .3s linear;

		&:hover{
			box-shadow: 0 0 8px rgba(#000,.5);
		}
	}

	.container{
		position: relative;

		@include media-breakpoint-up(lg) {
			&::before{
				content:'';
				display: block;
				width: 497px;
				height: 347px;
				position: absolute;
				right: 100%;
				transform: translateX(130px);
				bottom: -50px;
				background: red;
				background: url(../images/plantas-aquaticas.png);
			}
		}
	}

	.peixes{
		width: 100%;
		height: 100%;
		background-image: var(--lazybg, none);
		background-position: left top;
		background-repeat: no-repeat;
		position: absolute;
		z-index: -1;

		left: 50%;
		top: 0;
		transform: translateX(-50%);

		@include media-breakpoint-up(lg) {
			animation: peixes 10s linear infinite;
		}
	}

	.bolhas{
		width: 100%;
		height: 100%;
		background-image: var(--lazybg, none);
		background-position: left top;
		position: absolute;
		z-index: -1;
		@include media-breakpoint-up(lg) {
			animation: bolhas 10s linear infinite;
		}
		left: 0;
		top: 0;
	}
}

@keyframes bolhas{
	from {
		background-position: 0 0;
	}

	to{
		background-position: 0 -2039px;
	}
}

@keyframes peixes{
	from {
		background-position: -582px -188px;
	}

	to{
		background-position: calc(100% + 582px) calc(100% + 188px);
	}
}

.noticias__index,
.mergulho{
	position: relative;
	h2{
		margin-bottom: 20px;

		@include media-breakpoint-up(md) {
			font-size: 50px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 30px;
		}
	}

	.btn{
		font-family: 'Montserrat';font-weight: 700;
	}

}

.mergulho {
	@include media-breakpoint-down(lg) {
		img{
			@include img-fluid;
		}
	}
}

.btn__go__top{
	position: absolute;
	left: 50%;
	top: 100%;
	z-index: 500;
	background: #000;
	width: 100%;
	max-width: 415px;
	height: 70px;
	display:flex;
	justify-content: center;
	transform: translateX(-50%);
	mask-image: url(../images/mask-btn.webp);
	mask-size: 100% 100%;
	mask-repeat: no-repeat;
	mask-position: center top;

	.btn{
		flex-shrink: 0;
		padding: 0 0;
	}
}
