.btn-pill{
	border-radius: 2rem;
}

.btn-voltar{
	padding: .5rem 2rem;
	min-width: 190px;
}


.btn-white{
	@include button-variant(#FFF, #FFF);
	border-radius: 0;
}

.btn-outline-white{
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #FFF;
	color: #FFF;
	border-radius: 50%;

	&:hover{
		background: $primary;
		border-color: $primary;
		color: #FFF;
	}
}


.btn{
	&:disabled {
		opacity: 1;
		position: relative;

		$variants: (
			success: $success,
			warning: $warning,
			danger : $danger
		);

		&::before{
			content: 'Aceite os termos pra poder enviar.';
			display: block;
			width: 180px;
			padding: 10px;
			background: #000;
			border-radius: 4px;
			font-size: 12px;
			position: absolute;
			left: 50%;
			bottom: 100%;
			transform: translate(-50%, -10px);
			opacity: var(--opacity, 0);
			transition: all .3s linear;
		}

		&::after{
			content:'';
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-top: 10px solid #000;
			position: absolute;
			left: 50%;
			bottom: 100%;
			transform: translateX(-50%, 0);
			opacity: var(--opacity, 0);
			transition: all .3s linear;
		}

		&:hover{
			--opacity: 1;
			box-shadow: none !important;
		}

		@each $type, $color in $variants {
			&.btn-#{$type} {
				border-color: rgba($color, .5) !important;
				background-color: rgba($color, .5) !important;
			}
		}
	}
}
