.topo__desktop{
	background: #000;
	padding: 15px 0;
	position: sticky;
	top: 0;
	z-index: 995;

	.container{
		display: grid;
		grid-template-columns: 25% 75%;
	}

	.main__menu{
		padding-left: 30px;
		margin-left: auto;
		display: flex;
		align-items: center;
		font-size: 14px;

		li + li{
			margin-left: 20px;
		}

		a{
			transition: color .3s linear;
		}

		.active a,
		a:hover {
			color: #5eb1e3;
			text-decoration: none;
		}
	}
}
